<div class="hidden main-app">
	<img class="btn-hover-lsch" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/flecha.png" (click)="enviar_atras()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;z-index: 10">
	<img class="btn-hover efecto-destacado" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.enviar_sugerencia('Generador Operaciones Matemáticas','extras/generador-operaciones')" style="left: 8px;top: 65px;">
	<img class="btn-hover efecto-destacado" *ngIf="!global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.valoraciones('Generador Operaciones Matemáticas','extras/generador-operaciones')" style="top: 65px;">
    <img class="btn-hover-lsch" *ngIf="!global.isIframe" src="assets/images/abecedario/flecha.png" (click)="atras_landing()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;">
    <div id="contenedor-principal">
		<div id="gameContainer"></div>
	</div>
</div>
<div id="portada" *ngIf="Portada" @salidaHaciaArriba>
    <div class="row" style="display: flex;flex-wrap: wrap;align-items: center;justify-content: center;">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 text-center">   
            <br>
            <img draggable=false class="hidden-xs hidden-sm" src="assets/images/aplicativos/generador-operaciones/TITULO.png" style="width: 60%;">
            <img draggable=false class="hidden-md hidden-lg" src="assets/images/aplicativos/generador-operaciones/TITULO.png" style="width: 70%;">
            <br><br>
            <div style="font-family: 'Lato_Black';font-size: 20px;color: white">Este recurso te permitirá crear ejercicios listos para imprimir.</div>
            <br>
            <img draggable=false class="hidden-xs hidden-sm" (click)="iniciar(true)" src="assets/images/aplicativos/generador-operaciones/math.svg" style="width: 20%;cursor:pointer">
            <img draggable=false class="hidden-md hidden-lg" (click)="iniciar(true)" src="assets/images/aplicativos/generador-operaciones/math.svg" style="width: 20%;cursor:pointer">
            <br>
            <img draggable=false class="hidden-xs hidden-sm" (click)="iniciar(true)" src="assets/images/aplicativos/generador-operaciones/boton.png" style="width: 40%;cursor:pointer">
            <img draggable=false class="hidden-md hidden-lg" (click)="iniciar(true)" src="assets/images/aplicativos/generador-operaciones/boton.png" style="width: 40%;cursor:pointer">
<br><br>
            <img draggable=false class="hidden-xs hidden-sm" (click)="descargarlibroderegalo()" src="assets/images/aplicativos/generador-operaciones/libro.png" style="width: 20%;cursor:pointer">
            <img draggable=false class="hidden-md hidden-lg" (click)="descargarlibroderegalo()" src="assets/images/aplicativos/generador-operaciones/libro.png" style="width: 20%;cursor:pointer">
        </div>
        <div class="hidden-xs hidden-sm col-md-4 col-lg-4 text-center">   
            <img draggable=false class="hidden-xs hidden-sm" src="assets/images/aplicativos/generador-operaciones/{{numeroImagen}}.png" style="width: 90%;">
        </div>
        <div class="col-sm-12 col-md-12 text-center">
            <div class="text-center bienvenida" style="margin-top: 20px;margin-bottom: 10px;">
                <img draggable=false src="assets/images/aplicativos/generador-operaciones/logo-matematicapps.png" style="width: 20%;">
                <br>
                Un producto con ❤ de integritic.cl
            </div>
        </div>
    </div>
</div>
<div id="configuracion" *ngIf="Configuracion">
    <div class="row">
        <br><br>
        <div class="col-sm-12 col-md-12 text-center">
            <div class="title">Configuración de operaciones</div>
        </div>
        <div class="col-sm-12 col-md-12 text-center">
            <div class="contenedor-botones">
                <div [class]="isSumaVisible && activoSuma ? 'operacion suma-visible' :'operacion'" (click)="toggleCollapse(1)">
                    <h2><i class="icon icon-plus-circle2"></i><b class="hidden-xs hidden-sm"> Suma</b></h2>
                    <label class="switch-xl">
                        <input type="checkbox" [(ngModel)]="activoSuma" (click)="identificarOtroActivado('suma')">
                        <span class="switch-xl"></span>
                    </label>
                </div>
                <div [class]="isRestaVisible && activoResta ? 'operacion resta-visible' :'operacion'" (click)="toggleCollapse(2)">
                    <h2><i class="icon icon-minus-circle2"></i><b class="hidden-xs hidden-sm"> Resta</b></h2>
                    <label class="switch-xl">
                        <input type="checkbox" [(ngModel)]="activoResta" (click)="identificarOtroActivado('resta')">
                        <span class="switch-xl"></span>
                    </label>
                </div>
                <div [class]="isMultiplicacionVisible && activoMultiplicacion ? 'operacion multiplicacion-visible' :'operacion'" (click)="toggleCollapse(3)">
                    <h2><i class="icon icon-cancel-circle2"></i><b class="hidden-xs hidden-sm"> Multiplicación</b></h2>
                    <label class="switch-xl">
                        <input type="checkbox" [(ngModel)]="activoMultiplicacion" (click)="identificarOtroActivado('multiplicacion')">
                        <span class="switch-xl"></span>
                    </label>
                </div>
                <div [class]="isDivisionVisible && activoDivision ? 'operacion division-visible' :'operacion'" (click)="toggleCollapse(4)">
                    <h2><i class="icon icon-percent"></i><b class="hidden-xs hidden-sm"> División</b></h2>
                    <label class="switch-xl">
                        <input type="checkbox" [(ngModel)]="activoDivision" (click)="identificarOtroActivado('division')">
                        <span class="switch-xl"></span>
                    </label>
                </div>
            </div>
            <div class="row" *ngIf="activoSuma || activoResta || activoMultiplicacion || activoDivision">
                <div class="col">
                    <div class="collapse" [ngClass]="{'show': isSumaVisible}" id="suma">
                        <div [class]="activoSuma ? 'card card-body suma-visible' :'card card-body'" style="padding: 20px">
                            <div class="row" style="margin-bottom: 20px;">
                                <i class="icon icon-question4" style="    margin-right: 20px;float: right;cursor: pointer;font-size: 40px;" (click)="instruccion('Suma')"></i>
                                <h2>Configuración para las operaciones de <i class="icon icon-plus-circle2"></i><b> Suma</b></h2>
                                <div class="col-md-3">
                                    <h3>Cantidad de ejercicios a generar:</h3>
                                    <input type="range" min="1" max="30" step="1" value="10" class="slider" [(ngModel)]="CantidadOpSuma" (input)="onInputChange()">
                                    <h3><b>{{CantidadOpSuma}}</b></h3>
                                </div>
                                <div class="col-md-3">
                                    <h3>Cantidad de cifras de cada número:</h3>
                                    <input type="range" min="1" max="5" step="1" value="2" class="slider" [(ngModel)]="cifrasPorFila" (input)="onInputChange()">
                                    <h3><b>{{cifrasPorFila}}</b></h3>
                                </div>
                                <div class="col-md-3">
                                    <h3>Cantidad de números por ejercicio:</h3>
                                    <input type="range" min="2" max="5" step="1" value="2" class="slider" [(ngModel)]="numeroFilas" (input)="onInputChange()">
                                    <h3><b>{{numeroFilas}}</b></h3>
                                </div>
                                <div class="col-md-3">
                                    <h3>% de operaciones con reserva: <i class="icon icon-question4" style="cursor: pointer;font-size: 20px;" (click)="modalReserva()"></i></h3>
                                    <input type="range" min="0" max="100" step="10" value="50" class="slider" [(ngModel)]="conLlevadas" (input)="onInputChange()">
                                    <h3><b>{{conLlevadas}}%</b></h3>                                        
                                </div>
                            </div>
                            <hr>
                            <div class="row" style="margin-top: 50px;">
                                <div class="col-md-3"></div>
                                <div class="col-md-3">
                                    <h3>Orientación ejercicios:</h3>
                                    <select style="    width: 60%;margin: 0 auto;" (change)="QuitarDestacado('orientacionSuma')" id="orientacionSuma" class="select form-control" [(ngModel)]="OrientacionSuma" required>
                                        <option value="seleccione" selected disabled>Seleccione...</option>
                                        <option value="true">Horizontal</option>
                                        <option value="false">Vertical</option>
                                    </select>
                                    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                                        <h4 style="margin-right: 10px;"><b>Horizontal</b></h4>
                                        <label class="switch">
                                            <input type="checkbox" [(ngModel)]="OrientacionSuma" (input)="onInputChange()">
                                            <span class="switch"></span>
                                        </label>
                                        <h4 style="margin-left: 10px;"><b>Vertical</b></h4>
                                    </div> -->
                                </div>
                                <div class="col-md-3" *ngIf="OrientacionSuma ==='true'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Horizontal -->
                                    <div class="operacion-box" >          
                                        <label *ngFor="let valor of EjemploSuma.valores; let j = index">
                                            <b>{{ valor }}</b>                                                    
                                            <span *ngIf="j < EjemploSuma.valores.length - 1" class="icon-plus-circle2" style="color: #564d88; margin: 0px 7px;"></span>
                                        </label>
                                      
                                        <span style="color: #564d88; margin: 0px 7px;"> = </span>
                                    </div>
                                </div> 
                                <div class="col-md-2" *ngIf="OrientacionSuma ==='false'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Vertical -->
                                    <div class="operacion-box"  style="text-align: right; display: flex;align-items: center; flex-direction: column;">
                                        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                                            <div>
                                                <span class="icon-plus-circle2" style="color: #564d88; margin-right: 7px;"></span>
                                            </div>
                                            <div style="display: flex;flex-direction: column;align-items: flex-end;">
                                                <div *ngFor="let valor of EjemploSuma.valores; let j = index" style="display: flex; align-items: center;">
                                                    <!-- Agregar icono solo antes del segundo número -->                                                
                                                    <b>{{ valor }}</b>         
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="border: 1px solid black; margin: 5px 0; width: 100%;">
                                    </div>
                                </div> 
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="collapse" [ngClass]="{'show': isRestaVisible}" id="resta">
                        <div [class]="activoResta ? 'card card-body resta-visible' :'card card-body'" style="padding: 20px;">
                            <i class="icon icon-question4" style="    margin-right: 20px;float: right;cursor: pointer;font-size: 40px;" (click)="instruccion('Resta')"></i>
                            <h2>Configuración para las operaciones de <i class="icon icon-minus-circle2"></i> <b> Resta</b></h2>
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-md-3">
                                    <h3>Cantidad de ejercicios a generar:</h3>
                                    <input type="range" min="1" max="30" step="1" value="10" class="slider" [(ngModel)]="CantidadOpResta" (input)="onInputChange()">
                                    <h3><b>{{CantidadOpResta}}</b></h3>
                                </div>
                                <div class="col-md-3">
                                    <h3>Cantidad de cifras de cada número:</h3>
                                    <input type="range" min="1" max="5" step="1" value="2" class="slider" [(ngModel)]="cifrasPorFilaResta" (input)="onInputChange()">
                                    <h3><b>{{cifrasPorFilaResta}}</b></h3>
                                </div>
                                <div class="col-md-3">
                                    <h3>Cantidad de números por ejercicio:</h3>
                                    <input type="range" min="2" max="5" step="1" value="2" class="slider" [(ngModel)]="numeroFilasResta" (input)="onInputChange()">
                                    <h3><b>{{numeroFilasResta}}</b></h3>
                                </div>
                                <div class="col-md-3">
                                    <h3>% de operaciones con reserva: <i class="icon icon-question4" style="cursor: pointer;font-size: 20px;" (click)="modalReserva()"></i></h3>
                                    <input type="range" min="0" max="100" step="10" value="50" class="slider" [(ngModel)]="conLlevadasResta" (input)="onInputChange()">
                                    <h3><b>{{conLlevadasResta}}%</b></h3>                                
                                </div>
                            </div>
                            <hr>
                            <div class="row" style="margin-top: 50px;">
                                <div class="col-md-3"></div>
                                <div class="col-md-3">
                                    <h3>Orientación ejercicios:</h3>
                                    <select style="    width: 60%;margin: 0 auto;" (change)="QuitarDestacado('orientacionResta')" id="orientacionResta" class="select form-control" [(ngModel)]="OrientacionResta" required>
                                        <option value="seleccione" selected disabled>Seleccione...</option>
                                        <option value="true">Horizontal</option>
                                        <option value="false">Vertical</option>
                                    </select>
                                    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                                        <h4 style="margin-right: 10px;"><b>Horizontal</b></h4>
                                        <label class="switch">
                                            <input type="checkbox" [(ngModel)]="OrientacionResta" (input)="onInputChange()">
                                            <span class="switch"></span>
                                        </label>
                                        <h4 style="margin-left: 10px;"><b>Vertical</b></h4>
                                    </div> -->
                                </div>
                                <div class="col-md-3" *ngIf="OrientacionResta ==='true'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Horizontal -->
                                    <div class="operacion-box" >          
                                        <label *ngFor="let valor of EjemploResta.valores; let j = index">
                                            <b>{{ valor }}</b>                                                    
                                            <span *ngIf="j < EjemploResta.valores.length - 1" class="icon-minus-circle2" style="color: #564d88; margin: 0px 7px;"></span>
                                        </label>
                                      
                                        <span style="color: #564d88; margin: 0px 7px;"> = </span>
                                    </div>
                                </div> 
                                <div class="col-md-2" *ngIf="OrientacionResta ==='false'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Vertical -->
                                    <div class="operacion-box"  style="text-align: right; display: flex;align-items: center; flex-direction: column;">
                                        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                                            <div>
                                                <span class="icon-minus-circle2" style="color: #564d88; margin-right: 7px;"></span>
                                            </div>
                                            <div style="display: flex;flex-direction: column;align-items: flex-end;">
                                                <div *ngFor="let valor of EjemploResta.valores; let j = index" style="display: flex; align-items: center;">
                                                    <!-- Agregar icono solo antes del segundo número -->                                                
                                                    <b>{{ valor }}</b>         
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="border: 1px solid black; margin: 5px 0; width: 100%;">
                                    </div>
                                </div> 
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="collapse" [ngClass]="{'show': isMultiplicacionVisible}" id="multiplicacion">
                        <div [class]="activoMultiplicacion ? 'card card-body resta-visible' :'card card-body'" style="padding: 20px">
                            <i class="icon icon-question4" style="    margin-right: 20px;float: right;cursor: pointer;font-size: 40px;" (click)="instruccion('Multiplicación')"></i>
                            <h2>Configuración para las operaciones de <i class="icon icon-cancel-circle2"></i> <b> Multiplicación</b></h2>
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-md-4">
                                    <h3>Cantidad de ejercicios a generar:</h3>
                                    <input type="range" min="1" max="30" step="1" value="10" class="slider" [(ngModel)]="CantidadOpMultiplicacion" (input)="onInputChange()">
                                    <h3><b>{{CantidadOpMultiplicacion}}</b></h3>
                                </div>
                                <div class="col-md-4">
                                    <h3>Cantidad de cifras del multiplicando:</h3>
                                    <input type="range" min="1" max="5" step="1" value="2" class="slider" [(ngModel)]="multiplicandoCifras" (input)="onInputChange()">
                                    <h3><b>{{multiplicandoCifras}}</b></h3>
                                </div>
                                <div class="col-md-4">
                                    <h3>Cantidad de cifras del multiplicador:</h3>
                                    <input type="range" min="1" max="5" step="1" value="2" class="slider" [(ngModel)]="multiplicadorCifras" (input)="onInputChange()">
                                    <h3><b>{{multiplicadorCifras}}</b></h3>
                                </div>
                            </div>                    
                            <div class="row" style="margin-top: 50px;">
                                <div class="col-md-3"></div>
                                <div class="col-md-3">
                                    <h3>Orientación ejercicios:</h3>
                                    <select style="    width: 60%;margin: 0 auto;" (change)="QuitarDestacado('orientacionMultiplicacion')" id="orientacionMultiplicacion" class="select form-control" [(ngModel)]="OrientacionMultiplicacion" required>
                                        <option value="seleccione" selected disabled>Seleccione...</option>
                                        <option value="true">Horizontal</option>
                                        <option value="false">Vertical</option>
                                    </select>
                                    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                                        <h4 style="margin-right: 10px;"><b>Horizontal</b></h4>
                                        <label class="switch">
                                            <input type="checkbox" [(ngModel)]="OrientacionMultiplicacion" (input)="onInputChange()">
                                            <span class="switch"></span>
                                        </label>
                                        <h4 style="margin-left: 10px;"><b>Vertical</b></h4>
                                    </div> -->
                                </div>
                                <div class="col-md-3" *ngIf="OrientacionMultiplicacion ==='true'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Horizontal -->
                                    <div class="operacion-box" >          
                                        <label *ngFor="let valor of EjemploMultiplicacion.valores; let j = index">
                                            <b>{{ valor }}</b>                                                    
                                            <span *ngIf="j < EjemploMultiplicacion.valores.length - 1" class="icon-cancel-circle2" style="color: #564d88; margin: 0px 7px;"></span>
                                        </label>
                                      
                                        <span style="color: #564d88; margin: 0px 7px;"> = </span>
                                    </div>
                                </div> 
                                <div class="col-md-2" *ngIf="OrientacionMultiplicacion ==='false'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Vertical -->
                                    <div class="operacion-box"  style="text-align: right; display: flex;align-items: center; flex-direction: column;">
                                        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                                            <div>
                                                <span class="icon-cancel-circle2" style="color: #564d88; margin-right: 7px;"></span>
                                            </div>
                                            <div style="display: flex;flex-direction: column;align-items: flex-end;">
                                                <div *ngFor="let valor of EjemploMultiplicacion.valores; let j = index" style="display: flex; align-items: center;">
                                                    <!-- Agregar icono solo antes del segundo número -->                                                
                                                    <b>{{ valor }}</b>         
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="border: 1px solid black; margin: 5px 0; width: 100%;">
                                    </div>
                                </div> 
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="collapse" [ngClass]="{'show': isDivisionVisible}" id="division">
                        <div [class]="activoDivision ? 'card card-body division-visible' :'card card-body'" style="padding: 20px">
                            <i class="icon icon-question4" style="    margin-right: 20px;float: right;cursor: pointer;font-size: 40px;" (click)="instruccion('División')"></i>
                            <h2>Configuración para las operaciones de <i class="icon icon-percent"></i> <b> División</b></h2>
                            <div class="row" style="margin-bottom: 20px;">
                                <div class="col-md-4">
                                    <h3>Cantidad de ejercicios a generar:</h3>
                                    <input type="range" min="1" max="30" step="1" value="10" class="slider" [(ngModel)]="CantidadOpDivision" (input)="onInputChange()">
                                    <h3><b>{{CantidadOpDivision}}</b></h3>
                                </div>
                                <div class="col-md-4">
                                    <h3>Cantidad de cifras dividendo:</h3>
                                    <input type="range" min="1" max="5" step="1" value="2" class="slider" [(ngModel)]="dividendoCifras" (input)="onInputChange()">
                                    <h3><b>{{dividendoCifras}}</b></h3>
                                </div>
                                <div class="col-md-4">
                                    <h3>Cantidad de cifras divisor:</h3>
                                    <input type="range" min="1" max="5" step="1" value="2" class="slider" [(ngModel)]="divisorCifras" (input)="onInputChange()">
                                    <h3><b>{{divisorCifras}}</b></h3>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 50px;">
                                <div class="col-md-3"></div>
                                <div class="col-md-3">
                                    <h3>Orientación ejercicios:</h3>
                                    <select style="    width: 60%;margin: 0 auto;" (change)="QuitarDestacado('orientacionDivision')" id="orientacionDivision" class="select form-control" [(ngModel)]="OrientacionDivision" required>
                                        <option value="seleccione" selected disabled>Seleccione...</option>
                                        <option value="true">Horizontal</option>
                                        <option value="false">Vertical</option>
                                    </select>
                                    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                                        <h4 style="margin-right: 10px;"><b>Horizontal</b></h4>
                                        <label class="switch">
                                            <input type="checkbox" [(ngModel)]="OrientacionDivision" (input)="onInputChange()">
                                            <span class="switch"></span>
                                        </label>
                                        <h4 style="margin-left: 10px;"><b>Vertical</b></h4>
                                    </div> -->
                                </div>
                                <div class="col-md-3" *ngIf="OrientacionDivision ==='true'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Horizontal -->
                                    <div class="operacion-box" >          
                                        <label *ngFor="let valor of EjemploDivision.valores; let j = index">
                                            <b>{{ valor }}</b>                                                    
                                            <span *ngIf="j < EjemploDivision.valores.length - 1" class="icon-percent" style="color: #564d88; margin: 0px 7px;"></span>
                                        </label>
                                      
                                        <span style="color: #564d88; margin: 0px 7px;"> = </span>
                                    </div>
                                </div> 
                                <div class="col-md-2" *ngIf="OrientacionDivision ==='false'" style="display: flex;flex-direction: column;align-items: center;;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                                    <h3>Ejemplo:</h3>
                                    <!-- Vertical -->
                                    <div class="operacion-box"  style="text-align: right; display: flex;align-items: center; flex-direction: column;">
                                        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                                            <div>
                                                <span class="icon-percent" style="color: #564d88; margin-right: 7px;"></span>
                                            </div>
                                            <div style="display: flex;flex-direction: column;align-items: flex-end;">
                                                <div *ngFor="let valor of EjemploDivision.valores; let j = index" style="display: flex; align-items: center;">
                                                    <!-- Agregar icono solo antes del segundo número -->                                                
                                                    <b>{{ valor }}</b>         
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="border: 1px solid black; margin: 5px 0; width: 100%;">
                                    </div>
                                </div> 
                                <div class="col-md-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!activoSuma && !activoResta && !activoMultiplicacion && !activoDivision">
                <div class="col">
                    <div class="collapse show">
                        <div class="card card-body" style="padding: 20px">
                            <div class="row" style="margin-bottom: 20px;">
                                <h2>Para comenzar, active al menos una operación</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 text-center">
            <img draggable=false class="hidden-xs hidden-sm" *ngIf="!tieneOperaciones()" (click)="generarOperaciones()" src="assets/images/aplicativos/generador-operaciones/generar.png" style="width: 20%;cursor:pointer">
            <img draggable=false class="hidden-md hidden-lg" *ngIf="!tieneOperaciones()" (click)="generarOperaciones()" src="assets/images/aplicativos/generador-operaciones/generar.png" style="width: 10%;cursor:pointer">
<!-- 
            <img draggable=false *ngIf="Regenerar" class="hidden-xs hidden-sm" (click)="generarOperaciones()" src="assets/images/aplicativos/generador-operaciones/re-generar.png" style="width: 20%;cursor:pointer">
            <img draggable=false *ngIf="Regenerar" class="hidden-md hidden-lg" (click)="generarOperaciones()" src="assets/images/aplicativos/generador-operaciones/re-generar.png" style="width: 10%;cursor:pointer"> -->

            <img draggable=false *ngIf="tieneOperaciones()" class="hidden-xs hidden-sm" (click)="verlistado(true)" src="assets/images/aplicativos/generador-operaciones/verlistado.png" style="width: 20%;cursor:pointer">
            <img draggable=false *ngIf="tieneOperaciones()" class="hidden-md hidden-lg" (click)="verlistado(true)" src="assets/images/aplicativos/generador-operaciones/verlistado.png" style="width: 10%;cursor:pointer">

            <img draggable=false *ngIf="tieneOperaciones()" class="hidden-xs hidden-sm" (click)="modalImpresion()" src="assets/images/aplicativos/generador-operaciones/descargar.png" style="width: 20%;cursor:pointer">
            <img draggable=false *ngIf="tieneOperaciones()" class="hidden-md hidden-lg" (click)="modalImpresion()" src="assets/images/aplicativos/generador-operaciones/descargar.png" style="width: 10%;cursor:pointer">
        </div>
    </div>
    <div class="col-sm-12 col-md-12 text-center">
        <div class="text-center bienvenida" style="margin-top: 20px;margin-bottom: 10px;">
            <img src="assets/images/aplicativos/generador-operaciones/logo-matematicapps.png" style="width: 20%;">
            <br>
            Un producto con ❤ de integritic.cl
        </div>
    </div>
</div>
<div id="listado" *ngIf="Listado" @entradaDesdeAbajo @salidaHaciaAbajo>
    <div class="col-sm-12 col-md-12 text-center">
        <img draggable=false  *ngIf="tieneOperaciones()" class="hidden-xs hidden-sm" (click)="verlistado(false)" src="assets/images/aplicativos/generador-operaciones/volver.png" style="width: 15%;cursor:pointer">
        <img draggable=false  *ngIf="tieneOperaciones()" class="hidden-md hidden-lg" (click)="verlistado(false)" src="assets/images/aplicativos/generador-operaciones/volver.png" style="width: 20%;cursor:pointer">

        <img draggable=false  *ngIf="tieneOperaciones()" class="hidden-xs hidden-sm" (click)="modalImpresion()" src="assets/images/aplicativos/generador-operaciones/descargar.png" style="width: 15%;cursor:pointer">
        <img draggable=false  *ngIf="tieneOperaciones()" class="hidden-md hidden-lg" (click)="modalImpresion()" src="assets/images/aplicativos/generador-operaciones/descargar.png" style="width: 20%;cursor:pointer">

        <img draggable=false *ngIf="tieneOperaciones()" class="hidden-xs hidden-sm" (click)="generarOperaciones()" src="assets/images/aplicativos/generador-operaciones/re-generar.png" style="width: 15%;cursor:pointer">
        <img draggable=false *ngIf="tieneOperaciones()" class="hidden-md hidden-lg" (click)="generarOperaciones()" src="assets/images/aplicativos/generador-operaciones/re-generar.png" style="width: 20%;cursor:pointer">

        <img *ngIf="MostrarResultado" draggable=false class="hidden-xs hidden-sm" (click)="verResultado(false)" src="assets/images/aplicativos/generador-operaciones/nomostrar.png" style="width: 15%;cursor:pointer">
        <img *ngIf="MostrarResultado" draggable=false class="hidden-md hidden-lg" (click)="verResultado(false)" src="assets/images/aplicativos/generador-operaciones/nomostrar.png" style="width: 20%;cursor:pointer">

        <img *ngIf="!MostrarResultado" draggable=false class="hidden-xs hidden-sm" (click)="verResultado(true)" src="assets/images/aplicativos/generador-operaciones/mostrar.png" style="width: 15%;cursor:pointer">
        <img *ngIf="!MostrarResultado" draggable=false class="hidden-md hidden-lg" (click)="verResultado(true)" src="assets/images/aplicativos/generador-operaciones/mostrar.png" style="width: 20%;cursor:pointer">
    </div>
    <div class="col-sm-12 col-md-12 text-center">
        <div class="title">Listado Operaciones Generadas</div>
        <div class="title" [innerHTML]="getOperacionesActivas()"></div>   
        <br>
    </div>
    <div class="row">
        <div class="col-12 text-center mt-3">
          <div style="margin: 30px">
            <div class="row">
                <ng-container *ngFor="let tipo of getTiposOperacion(); let tipoIndex = index">
                    <ng-container *ngFor="let operacion of operacionesGeneradas[tipo]; let i = index">
                      
                      <!-- Si la orientación es horizontal -->
                      <ng-container *ngIf="getOrientacion(tipo)">
                        <label class="text-center mb-3" style="margin-right: 5px;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                          <div class="operacion-box" style="display: flex; align-items: center;">
                            <b [ngStyle]="{'background-color': getColor(tipo), 'padding': '5px 6px', 'color': 'black', 'border-radius': '170px', 'margin-bottom': '5px','font-size': '20px'}">
                              {{ getOperacionIndex(tipoIndex, i) }}.-
                            </b>                        
                            <div *ngFor="let valor of operacion.valores; let j = index" style="display: flex; align-items: center;">
                              <input maxlength="6" class="form-control" type="text" [(ngModel)]="operacion.valores[j]" (input)="guardarOperaciones(getOperacionIndex(tipoIndex, i), j,tipo,i)" [attr.data-operador]="getOperacionIndex(tipoIndex, i) + '-' + j">                    
                              <span *ngIf="j < operacion.valores.length - 1" class="{{ getOperatorIcon(tipo) }}" style="color: #564d88; margin: 0px 7px;"></span>
                            </div>
                            <span *ngIf="MostrarResultado" style="color: #564d88; margin: 0px 7px;"> = </span>
                            <input maxlength="6" *ngIf="MostrarResultado" class="form-control" disabled type="text" [(ngModel)]="operacion.resultado" [attr.data-resultado]="getOperacionIndex(tipoIndex, i)" style="width: 80px; text-align: right; font-weight: bold;">
                          </div>                      
                        </label>
                      </ng-container>
                  
                      <!-- Si la orientación es vertical -->
                      <ng-container *ngIf="!getOrientacion(tipo)">
                        <label class="text-center mb-3" style="margin-right: 5px;padding: 10px;border: 1px solid #ddd;border-radius: 5px;background-color: #f9f9f9;">
                        <div>
                            <b [ngStyle]="{'background-color': getColor(tipo), 'padding': '5px 6px', 'color': 'black', 'border-radius': '170px', 'margin-bottom': '5px','font-size': '20px'}">
                            {{ getOperacionIndex(tipoIndex, i) }}.-
                            </b>
                        </div>
                          <div class="operacion-box" style="display: flex; flex-direction: column;text-align: right;    align-items: flex-end;    margin-top: 10px;">
                            <div *ngFor="let valor of operacion.valores; let j = index" style="display: flex; align-items: center;">
                              <span *ngIf="j > 0" class="{{ getOperatorIcon(tipo) }}" style="color: #564d88; margin-right: 7px;"></span>
                              <input maxlength="6" class="form-control" type="text" [(ngModel)]="operacion.valores[j]" (input)="guardarOperaciones(getOperacionIndex(tipoIndex, i), j,tipo,i)" [attr.data-operador]="getOperacionIndex(tipoIndex, i) + '-' + j"style="width: 80px; text-align: right; margin-bottom: 3px;">
                            </div>
                            <hr *ngIf="MostrarResultado" style="border: 1px solid black; margin: 5px 0; width: 100%;">
                            <input maxlength="6" *ngIf="MostrarResultado" class="form-control" disabled type="text" [(ngModel)]="operacion.resultado" [attr.data-resultado]="getOperacionIndex(tipoIndex, i)" style="width: 80px; text-align: right; font-weight: bold;">
                          </div>
                        </label>
                      </ng-container>
                    </ng-container>
                    <br>
                </ng-container>
            </div>
          </div>
        </div>
      </div>
    <div class="col-sm-12 col-md-12 text-center">
        <div class="text-center bienvenida" style="margin-top: 20px;margin-bottom: 10px;">
            <img src="assets/images/aplicativos/generador-operaciones/logo-matematicapps.png" style="width: 20%;">
            <br>
            Un producto con ❤ de integritic.cl
        </div>
    </div>
</div>
<div id="modal-instruccion-operaciones" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" style="margin-top: 100px" role="document">
        <div class="modal-content">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="font-size: 30px"><b>Instrucciones {{inputTitulo}}</b></h5>
            </div>
            <div class="modal-body text-center" style="padding: 40px;font-size: 20px;text-align: justify;">
                {{inputTexto}}
            </div>
        </div>
    </div>
</div>
<div id="modal-reserva" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" style="margin-top: 100px" role="document">
        <div class="modal-content">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="font-size: 30px"><b>Configuración de reservas</b></h5>
            </div>
            <div class="modal-body text-center" style="padding: 40px;font-size: 20px;text-align: justify;">
                📢 ¿Por qué es imposible evitar la reserva en sumas y restas largas?<br><br>
                Cuando hacemos una suma o una resta con muchos números y varias cifras, es inevitable que en algún momento tengamos reservas.<br>
                👉 Ejemplo en sumas: Si sumamos varios números de 2 o más cifras, en algún momento la suma de una columna superará 9, lo que obligará a llevar un número a la siguiente columna.<br>
                👉 Ejemplo en restas: Si restamos varios números grandes, en algún punto necesitaremos pedir prestado de la siguiente cifra para que la operación sea válida.<br><br>
                💡 Conclusión: Mientras más números haya en la operación y más cifras tengan, es matemáticamente imposible evitar todas las reservas. Solo podemos reducirlos, pero no eliminarlos completamente. 😃
            </div>
        </div>
    </div>
</div>
<div id="modal-impresion" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" style="margin-top: 70px" role="document">
        <div class="modal-content" style="border-radius: 20px;">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color: #673AB7;font-size: 40px;opacity: 1 !important;">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="width:100%;font-size: 20px">Opciones adicionales</h5>
            </div>
            <div class="modal-body text-center">
                <div>
                    Configura los datos que deseas integrar a tu documento
                </div>
                <br>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <div style="width: 10%"></div>
                    <input class="hidden" style="width: 10%;" type="checkbox" [(ngModel)]="checkTitulo">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;"><h4>Título:</h4></div>
                    <input style="width: 70%;" class="form-control" type="text" name="Título" id="Título" value="Operaciones Matemáticas a Resolver">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkInstruccion">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;"><h4>Instrucciones:</h4></div>
                    <input style="width: 70%;" class="form-control" type="text" maxlength="90" name="Instrucciones" id="Instrucciones" value="Resuelve las siguientes operaciones matemáticas de cálculo mental.">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkNombre">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;"><h4>Nombre:</h4></div>
                    <input style="width: 70%;" class="form-control" type="text" name="Nombre" id="Nombre">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkCurso">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;"><h4>Curso:</h4></div>
                    <input style="width: 70%;" class="form-control" type="text" name="Curso" id="Curso">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkColegio">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;"><h4>Colegio:</h4></div>
                    <input style="width: 70%;" class="form-control" type="text" name="Colegio" id="Colegio" [(ngModel)]="this.DATOS_USUARIO.institucion">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkFecha">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;"><h4>Fecha:</h4></div>
                    <input style="width: 61%;" class="form-control" type="date" name="Fecha" id="Fecha">
                    <button class="btn btn-verde" (click)="setFechahoy()"><i class="icon-calendar" style="margin-right: 5px;"></i> Hoy</button>
                </div>
                <div class="flex" style="align-items: center">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkDocente">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;"><h4>Docente:</h4></div>
                    <input style="width: 70%;" class="form-control" type="text" name="Docente" id="Docente" [(ngModel)]="this.DATOS_USUARIO.usuario">
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-3">
                        <div style="margin: 0px 10px;text-align: left;"><h4>Tamaño hoja:</h4></div>
                        <select class="select form-control" id="TAMANIO" [(ngModel)]="TAMANIO" required>
                            <option value="letter" selected>Carta</option>
                            <option value="legal">Oficio</option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <div style="margin: 0px 10px;text-align: left;"><h4>Tipo de letra:</h4></div>
                        <select class="select form-control" id="FuenteActual" [(ngModel)]="FuenteActual" required>
                            <option value="Arial" selected>Arial</option>
                            <option value="Tahoma" selected>Tahoma</option>
                            <option value="Escolar2" selected>Escolar2</option>
                            <option value="Century Gothic" selected>Century Gothic</option>
                            <option value="OpenDyslexic_3" selected>Dislexia</option>
                            <!-- <option value="Integritic_lsch_1" selected>LSCH</option> -->
                        </select>
                    </div>                    
                    <div class="col-sm-3">
                        <h4>Tamaño de las operaciones:</h4>
                        <input type="range" min="10" max="20" step="1" value="15" class="slider" [(ngModel)]="fontSize">                        
                        <div style="border: 3px solid rgb(51, 205, 143);border-radius: 7px;margin-top: 5px;">
                            <h5>Ejemplo del tamaño:</h5>
                            <b [ngStyle]="{'font-size': fontSize + 'px', 'font-family': FuenteActual}">123+456</b>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div style="margin: 0px 10px;text-align: left;"><h4>Agregar fondo:</h4>
                            <label class="switch">
                                <input type="checkbox" [(ngModel)]="activoFondo">
                                <span class="switch"></span>
                            </label>
                        </div>
                    </div>                    
                </div>
                <hr>
                <img draggable=false class="hidden-xs hidden-sm" (click)="generatePDF()" src="assets/images/aplicativos/generador-operaciones/descargar.png" style="width: 20%;cursor:pointer">
                <img draggable=false class="hidden-md hidden-lg" (click)="generatePDF()" src="assets/images/aplicativos/generador-operaciones/descargar.png" style="width: 20%;cursor:pointer">
            </div>
        </div>
        </div>
    </div>
</div> 
<app-valoraciones></app-valoraciones>