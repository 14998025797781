<div class="hidden main-app">
	<img class="btn-hover-lsch pulso-css" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/flecha.png" (click)="enviar_atras()" style="margin-top: 20px;margin-left: 15px;width: 40px;position: absolute;">
	<img class="btn-hover efecto-destacado" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.enviar_sugerencia('Ruidómetro','extras/ruidometro')">
	<img class="btn-hover efecto-destacado" *ngIf="!global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.valoraciones('Ruidómetro','extras/ruidometro')" style="top: 14px;">
    <div id="contenedor-principal" style="background: #5f0d50;">
		<div id="gameContainer" style=" background: #5f0d50;"></div>
	</div>
	<div style="position: fixed; width: 100%; height: 100%; top: 0;left: 0; background: #000000a6; text-align: center; padding-top: 12vh;" id="modal-instrucciones" *ngIf="!global.isIframe">
		<img src="assets/images/aplicativos/ruidometro/imagen-modal.png" alt="" style="max-width: 700px;">
		<!-- <div style="
			width: 50%;
			height: 40%;
			background: white;
			position: fixed;
			top: 10vh;
			left: 25vw;
			font-size: 25px;
			padding: 30px;
			border-radius: 60px;
			text-align: center;
			border-color: #e2cccd;
			border-width: 10px;
			background: #130a2e;
			color: #fae6df;
			border-style: solid;
			box-shadow: 10px 15px 5px black;">
			Controla el ruido de tu sala de manera entretenida.<br>
				Por cada 10 segundos de silencio (configurable), el curso gana una estrella por no despertar a la mascota ( Fiu o Jiro)<br>
				Si el curso llega a ruido nivel máximo, se pierde 1 estrella.<br>
				¡A jugar controlando el ruido!
				<br>
				<button (click)="iniciarJuegoPausado()" style="color: black;">Iniciar</button>
		</div> -->
		<br>
		<img (click)="iniciarJuegoPausado()" src="assets/images/aplicativos/ruidometro/boton-modal.png" class="btn-hover-lsch" alt="" style="zoom: .5; position: relative; margin-top: -100px; cursor: pointer;">
	</div>
</div>
<app-valoraciones></app-valoraciones>
