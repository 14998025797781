import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
// import Phaser from 'phaser';
import { ApiService } from 'src/app/inicio/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, animate, style } from '@angular/animations';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var Howl: any;
declare var $: any;
declare var jsPDF: any;
import { autoTable } from 'jspdf-autotable'

import html2canvas from 'html2canvas';

var ContenedorGeneralGlobal;
var PantallaPresentacionGlobal;
var Principal;
@Component({
  selector: 'app-generador-operaciones',
  templateUrl: './generador-operaciones.component.html',
  styleUrls: ['./generador-operaciones.component.css'],
  animations: [
    trigger('salidaHaciaArriba', [
      transition(':leave', [
        animate('0.3s', style({ transform: 'translateY(-100%)', opacity: 1 }))
      ])
    ]),
    trigger('salidaHaciaAbajo', [
      transition(':leave', [
        animate('0.3s', style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ]),
    trigger('entradaDesdeAbajo', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('0.3s ease-out', style({ transform: 'translateY(0)', opacity: 1 }))
      ])
    ])
  ]
})

export class GeneradorOperacionesComponent implements OnInit { 
  fonts = ["Arial","Tahoma","Escolar2","Century Gothic","OpenDyslexic_3","Integritic_lsch_1"];
  url_api = "https://api.gateway.integritic.cl/sv5/";
  fechaSeleccionada: string;
  fontSize:any = 15;
  alerta;
  inputTitulo:any = "";
  inputTexto:any = "";
  activoFondo: boolean = true;
  //SUMA
  OrientacionSuma: any = 'seleccione';
  CantidadOpSuma: number = 10;
  cifrasPorFila: number = 2;
  numeroFilas: number = 2;
  conLlevadas: number = 50;
  //SUMA
  //RESTA
  OrientacionResta: any = 'seleccione';
  CantidadOpResta: number = 10;
  cifrasPorFilaResta: number = 2;
  numeroFilasResta: number = 2;
  conLlevadasResta: number = 50;
  //RESTA
  //MULTIPLICACION
  // OrientacionMultiplicacion: boolean = true;
  CantidadOpMultiplicacion: number = 10;
  multiplicandoCifras: number = 2;
  multiplicadorCifras: number = 1;
  OrientacionMultiplicacion: any = 'seleccione';
  //MULTIPLICACION
  //DIVISION
  // OrientacionDivision: boolean = true;
  CantidadOpDivision: number = 10;
  dividendoCifras: number = 2;
  divisorCifras: number = 1;
  OrientacionDivision: any = 'seleccione';
  //DIVISION
  operacionesGeneradas: {
    suma: { valores: number[], resultado: number }[],
    resta: { valores: number[], resultado: number }[],
    multiplicacion: { valores: number[], resultado: number }[],
    division: { valores: number[], resultado: number }[]
  } = {
    suma: [],
    resta: [],
    multiplicacion: [],
    division: []
  };
  numeroImagen:any = 1;
  Portada:any = true;
  Configuracion:any = false;
  Listado:any = false;
  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  audioClick:any = new Audio('assets/recursos/click.mp3');
  OPCIONES_ADICIONALES_PDF:any = {
    Título: "Operaciones Matemáticas a Resolver",
    Nombre: "",
    Curso: "",
    Fecha: "",
    Colegio: "",
    Docente: "",
    Instrucciones: "Resuelve las siguientes operaciones matemáticas de cálculo mental."
  }
  checkTitulo: boolean = true;
  checkInstruccion: boolean = true;
  checkNombre: boolean = true;
  checkCurso: boolean = true;
  checkColegio: boolean = true;
  checkFecha: boolean = true;
  checkDocente: boolean = true;
  FuenteActual:any = "Arial";
  TAMANIO:any = "letter";
  PLATAFORMA:any = "TT";
  DATOS_USUARIO = {
    'usuario': '',
    'institucion': ''
  }
  ORDEN_CURSO = [
    'NT 1','NT 2','1° Básico','2° Básico','3° Básico','4° Básico','5° Básico','6° Básico','7° Básico','8° Básico','1° Medio','2° Medio','3° Medio','4° Medio',
  ];
  isSumaVisible = false;
  isRestaVisible = false;
  isMultiplicacionVisible = false;
  isDivisionVisible = false;
  activoSuma:any = false;
  activoResta:any = false;
  activoMultiplicacion:any = false;
  activoDivision:any = false;
  OperacionActual:any= "";
  Regenerar:any = false;
  EjemploSuma:any= "";
  EjemploResta:any= "";
  EjemploMultiplicacion:any= "";
  EjemploDivision:any= "";
  MostrarResultado:any = false;
  intervalo: any;

  constructor( public global:GlobalService, public api:ApiService,private router: Router,private route: ActivatedRoute,private sanitizer: DomSanitizer){ 
    this.alerta = this.global.alerta;
    Principal = this;
    this.config = {
      type: Phaser.CANVAS,
      roundPixels: true,
      scale: {
        width: 1920,
        height: 900,
        parent: 'gameContainer',
        fullscreenTarget: 'gameContainer',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      dom: {
          createContainer: true
      },
      backgroundColor: '#ffffff',
      // scene: [PantallaPresentacion,ContenedorGeneral]
    };
    this.iniciar_juego();
    this.url_api = window.location.origin.includes('localhost') ? "http://127.0.0.1:3445/" : "https://api.gateway.integritic.cl/sv5/";
    this.url_api = "https://api.gateway.integritic.cl/sv5/";
    const hoy = new Date();
    this.fechaSeleccionada = hoy.toISOString().slice(0, 10);
    if(global.isIframe){
      this.route.params.subscribe((params2:any) => {
        this.getDatos(params2);
      });
    }
  }
  iniciarCambioImagenes() {
    this.intervalo = setInterval(() => {
      if (this.numeroImagen < 7) {
        this.numeroImagen++;
      } else {
        this.numeroImagen = 1;
        // clearInterval(this.intervalo); // Detiene el intervalo cuando llega a 7
      }
    }, 700);
  }
  
  detenerCambioImagenes() {
    clearInterval(this.intervalo);
  }
  async getDatos(params2){
    let datos = await this.api.getDatosUsuario(params2);
    this.DATOS_USUARIO = {
      'usuario': datos["usuario"].toUpperCase(),
      'institucion': datos["institucion"].toUpperCase()
    }
  }
  toggleCollapse(option: number) {
    let op_numero = option == 1 ? "suma" : (option == 2 ? "resta" : (option == 3 ? "multiplicacion" : (option == 4 ? "division" : "division")));
    if(this.OperacionActual){
      if(op_numero != this.OperacionActual){
        if(this.activoSuma && this.OrientacionSuma == "seleccione"){
          this.alerta.fire({
            title: "Debe seleccionar la orientación de las operaciones",
            icon: 'warning',
            confirmButtonText: "Ok"
          });
          this.OperacionActual = "suma";
          this.toggleCollapse(1);
          $('#orientacionSuma').addClass('error pulso-css');
          return;
        }else if(this.activoResta && this.OrientacionResta == "seleccione"){
          this.alerta.fire({
            title: "Debe seleccionar la orientación de las operaciones",
            icon: 'warning',
            confirmButtonText: "Ok"
          });
          this.OperacionActual = "resta";
          this.toggleCollapse(2);
          $('#orientacionResta').addClass('error pulso-css');
          return;
        }else if(this.activoMultiplicacion && this.OrientacionMultiplicacion == "seleccione"){
          this.alerta.fire({
            title: "Debe seleccionar la orientación de las operaciones",
            icon: 'warning',
            confirmButtonText: "Ok"
          });
          this.OperacionActual = "multiplicacion";
          this.toggleCollapse(3);
          $('#orientacionMultiplicacion').addClass('error pulso-css');
          return;
        }else if(this.activoDivision && this.OrientacionDivision == "seleccione"){
          this.alerta.fire({
            title: "Debe seleccionar la orientación de las operaciones",
            icon: 'warning',
            confirmButtonText: "Ok"
          });
          this.OperacionActual = "division";
          this.toggleCollapse(4);
          $('#orientacionDivision').addClass('error pulso-css');
          return;
        }else{
          console.log('continua')
        }
      }
    }
    this.isSumaVisible = false;
    this.isRestaVisible = false;
    this.isMultiplicacionVisible = false;
    this.isDivisionVisible = false;

    if (option === 1) {
      this.OperacionActual = "suma";

      this.isSumaVisible = true;
    } else if (option === 2) {
      this.OperacionActual = "resta";

      this.isRestaVisible = true;
    } else if (option === 3) {
      this.OperacionActual = "multiplicacion";

      this.isMultiplicacionVisible = true;
    } else {
      this.OperacionActual = "division";

      this.isDivisionVisible = true;
    }
  }
  identificarOtroActivado(operacion: string) {
    if(this.tieneOperaciones()){
      this.mensajeReinicio(operacion);
      return;
    }
    const operaciones = {
      suma: this.activoSuma,
      resta: this.activoResta,
      multiplicacion: this.activoMultiplicacion,
      division: this.activoDivision
    };
    if (!operaciones[operacion]) {
      const nuevaOperacion = Object.keys(operaciones).find(op => operaciones[op]);
  
      if (nuevaOperacion) {
        this.OperacionActual = nuevaOperacion;
      } else {
        this.OperacionActual = null;
      }
    }
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Generador Operaciones Matemáticas"}, '*');
  }
  atras_landing(){
    window.location.href = "/extras/generador-operaciones";
  }
  async iniciar_juego(){
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      $("#contenedor-principal").html(`<div id="gameContainer" style=" background: #957af8;"></div>`);
      this.phaserGame = new Phaser.Game(this.config);
    },1200)
    await this.global.offLoading(2500);
    $("body").css("background-color", "#957af8");
    this.getEjemploSuma();
    this.getEjemploResta();
    this.getEjemploMultiplicacion();
    this.getEjemploDivision();
    this.iniciarCambioImagenes();
  }
  iniciar(estado){
    if(estado){
      this.Portada = false;
      this.Configuracion = true;
      this.audioClick.play();
      this.detenerCambioImagenes();
    }else{
      this.alerta.fire({
        title: "Próximamente",
        text: "Estamos trabajando en este modo de juego",
        icon: 'success',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      });
    }
  }
  ngOnInit(): void {
    this.api.set_kpi_plataformas("Generador Operaciones Matemáticas","Ingresó al módulo");
  }
async generarOperaciones() {
  if(this.activoSuma || this.activoResta || this.activoMultiplicacion || this.activoDivision){
    if(this.activoSuma && this.OrientacionSuma == "seleccione"){
      this.alerta.fire({
        title: "Debe seleccionar la orientación de las operaciones",
        icon: 'warning',
        confirmButtonText: "Ok"
      });
      this.OperacionActual = "suma";
      this.toggleCollapse(1);
      $('#orientacionSuma').addClass('error pulso-css');
      return;
    }else if(this.activoResta && this.OrientacionResta == "seleccione"){
      this.alerta.fire({
        title: "Debe seleccionar la orientación de las operaciones",
        icon: 'warning',
        confirmButtonText: "Ok"
      });
      this.OperacionActual = "resta";
      this.toggleCollapse(2);
      $('#orientacionResta').addClass('error pulso-css');
      return;
    }else if(this.activoMultiplicacion && this.OrientacionMultiplicacion == "seleccione"){
      this.alerta.fire({
        title: "Debe seleccionar la orientación de las operaciones",
        icon: 'warning',
        confirmButtonText: "Ok"
      });
      this.OperacionActual = "multiplicacion";
      this.toggleCollapse(3);
      $('#orientacionMultiplicacion').addClass('error pulso-css');
      return;
    }else if(this.activoDivision && this.OrientacionDivision == "seleccione"){
      this.alerta.fire({
        title: "Debe seleccionar la orientación de las operaciones",
        icon: 'warning',
        confirmButtonText: "Ok"
      });
      this.OperacionActual = "division";
      this.toggleCollapse(4);
      $('#orientacionDivision').addClass('error pulso-css');
      return;
    }else{
      console.log('continua')
    }
    $('#modalaso').removeClass('hidden').show();
    this.operacionesGeneradas = {
      suma: [],
      resta: [],
      multiplicacion: [],
      division: []
    };

    if (this.activoSuma) {
        await this.generarSumaAsync();
    }
    if (this.activoResta) {
        await this.generarRestaAsync();
    }
    if (this.activoMultiplicacion) {
        await this.generarMultiplicacionAsync();
    }
    if (this.activoDivision) {
        await this.generarDivisionAsync();
    }
    this.Regenerar = true;
    setTimeout(() => {
      $('#modalaso').addClass('hidden').hide();
      this.verlistado(true);
    }, 800);
    this.api.set_kpi_plataformas("Generador Operaciones Matemáticas","Generó operaciones");
  }else{
    this.alerta.fire({
      title: "Debe seleccionar al menos una opción",
      icon: 'warning',
      confirmButtonText: "Ok"
    });
    return;
  }
}

async generarSumaAsync() {
  for (let i = 0; i < this.CantidadOpSuma; i++) {
      let sumandos = [];
      let referencia = this.generarNumeroAleatorioSuma(this.cifrasPorFila, true);

      for (let j = 0; j < this.numeroFilas; j++) {
          let permitirLlevadas = Math.random() * 100 < this.conLlevadas;
          let numeroGenerado;
          let intentos = 0;

          do {
              numeroGenerado = this.generarNumeroAleatorioSuma(this.cifrasPorFila, permitirLlevadas);
              intentos++;
              if (intentos > 10) break;
          } while (sumandos.includes(numeroGenerado) || numeroGenerado > referencia);

          sumandos.push(numeroGenerado);
          referencia = numeroGenerado;
      }

      let resultado = sumandos.reduce((acc, num) => acc + num, 0);
      
      this.operacionesGeneradas.suma.push({
          valores: sumandos,
          resultado: resultado
      });

      if (i % 10 === 0) await new Promise(resolve => setTimeout(resolve, 0));
  }
}

async generarRestaAsync() {
  for (let i = 0; i < this.CantidadOpResta; i++) {
      let permitirLlevadas = Math.random() * 100 < this.conLlevadas;
      let minuendo = this.generarNumeroAleatorioResta(this.cifrasPorFilaResta, permitirLlevadas, Number.MAX_SAFE_INTEGER);
      let valores = [minuendo];
      let valorRestante = minuendo; // Mantiene el valor restante después de cada resta

      for (let j = 1; j < this.numeroFilasResta; j++) {
          if (valorRestante <= 0) break; // Detiene la iteración si el valor restante llega a 0

          let maxSustraendo = Math.max(1, Math.floor(valorRestante / 2)); // Limita el sustraendo al 50% del valor restante
          let sustraendo;
          let intentos = 0;

          do {
              sustraendo = this.generarNumeroAleatorioResta(this.cifrasPorFilaResta, permitirLlevadas, maxSustraendo);
              intentos++;
              if (intentos > 10) break;
          } while (sustraendo > maxSustraendo); // Se asegura de que no reste más de lo permitido

          valores.push(sustraendo);
          valorRestante -= sustraendo; // Actualiza el valor restante
      }

      let resultado = valores.reduce((acc, num) => acc - num);

      this.operacionesGeneradas.resta.push({
          valores: valores,
          resultado: resultado
      });

      if (i % 10 === 0) await new Promise(resolve => setTimeout(resolve, 0));
  }
}

async generarMultiplicacionAsync() {
  for (let i = 0; i < this.CantidadOpMultiplicacion; i++) {
      let multiplicando = this.generarNumeroAleatorioMultiplicacion(this.multiplicandoCifras);
      let multiplicador = this.generarNumeroAleatorioMultiplicacion(this.multiplicadorCifras);
      let resultado = multiplicando * multiplicador;

      this.operacionesGeneradas.multiplicacion.push({
          valores: [multiplicando, multiplicador],
          resultado: resultado
      });

      if (i % 10 === 0) await new Promise(resolve => setTimeout(resolve, 0));
  }
}

async generarDivisionAsync() {
  for (let i = 0; i < this.CantidadOpDivision; i++) {
      let dividendo, divisor;
      let intentos = 0;

      do {
          dividendo = this.generarNumeroAleatorioDivision(this.dividendoCifras);
          divisor = this.generarNumeroAleatorioDivision(this.divisorCifras);
          intentos++;
          if (intentos > 10) break;
      } while (divisor === 0 || divisor === 1 || dividendo <= divisor || dividendo % divisor !== 0);

      let resultado = dividendo / divisor;

      this.operacionesGeneradas.division.push({
          valores: [dividendo, divisor],
          resultado: resultado
      });

      if (i % 10 === 0) await new Promise(resolve => setTimeout(resolve, 0));
  }
}
modalImpresion(){
  if (this.tieneOperaciones()) {
    $('#modal-impresion').modal();
  } else {
      console.error("Error: No se generaron operaciones, PDF no generado.");
  }
}
  generarNumeroAleatorio(min: number, max: number,cantidadCifras): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  generarNumeroAleatorioSuma(cantidadCifras: number, permitirLlevadas: boolean): number {
    let numero = "";
    let min = 1;
    let max = 9;
  
    for (let x = 0; x < cantidadCifras; x++) {
        let usarLlevada = permitirLlevadas && Math.random() * 100 < this.conLlevadas;
        let limiteSuperior = usarLlevada ? max : (x === 0 ? max : 5); // Más llevadas en las cifras más altas
        numero += Math.floor(Math.random() * (limiteSuperior - min + 1)) + min;
    }

    return Number(numero);
  }
  generarNumeroAleatorioResta(cantidadCifras: number, permitirLlevadas: boolean, maxValor: number): number {
    let numero = "";
    let min = 1;
    let max = Math.min(9, maxValor); // Asegura que el número generado no supere el valor permitido
  
    for (let x = 0; x < cantidadCifras; x++) {
        let limiteSuperior = (permitirLlevadas && Math.random() * 100 < this.conLlevadas) ? max : 5;
        numero += Math.floor(Math.random() * (limiteSuperior - min + 1)) + min;
    }
    return Math.min(Number(numero), maxValor); // Evita que se genere un número mayor al permitido
  }
  generarNumeroAleatorioMultiplicacion(cifras: number): number {
    let min = 10 ** (cifras - 1); 
    let max = 10 ** cifras - 1;    
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  generarNumeroAleatorioDivision(cifras: number): number {
    let min = 10 ** (cifras - 1); 
    let max = 10 ** cifras - 1;    
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  instruccion(tipo){
    this.inputTitulo = tipo;

    this.inputTexto = this.instrucciones[tipo];
    $('#modal-instruccion-operaciones').modal();
  }
  modalReserva(){
    $('#modal-reserva').modal();
  }
  instrucciones = {
    'Suma' : `Elige el número de cifras de cada fila de la operación y cuantas filas tendrá cada suma.\n
        Mediante el deslizador de "reservas" puedes establecer una tasa de cuantas de las sumas individuales incluirán una reserva. Ponlo a cero para no tener ninguna reserva. Ten en cuenta que si el número de filas aumenta, es casi imposible no tener "reservas".
        \nFinalmente, pulsa el botón Descargar para que se descargue la hoja generada automáticamente.`,
    'Resta' : `Elige el número de cifras de cada fila de la operación y cuantas filas tendrá cada suma.\n
        Mediante el deslizador de "reservas" puedes establecer una tasa de cuantas de las restas individuales incluirán una reserva. Ponlo a cero para no tener ninguna reserva.\n
        Finalmente, pulsa el botón Descargar para que se descargue la hoja generada automáticamente.`,
    'Multiplicación' : `Elige el número de cifras de multiplicando y multiplicador usando los deslizadores.\n
        Finalmente, pulsa el botón Descargar para que se descargue la hoja generada automáticamente.`,
    'División' : `Elige el número de cifras de dividendo y divisor usando los deslizadores.\n
        Finalmente, pulsa el botón Descargar para que se descargue la hoja generada automáticamente.`
  }
  generatePDF() {
    $('#modalaso').removeClass('hidden').show();
  
    let fechaOriginal = $('#Fecha').val();
    if (fechaOriginal != '') {
      let partesFecha = fechaOriginal.split('-');
      fechaOriginal = `${partesFecha[2]}-${partesFecha[1]}-${partesFecha[0]}`;
    } else {
      fechaOriginal = '_______/_______/_______';
    }
    this.OPCIONES_ADICIONALES_PDF = {
      "Título": $('#Título').val(),
      "Nombre": $('#Nombre').val(),
      "Curso": $('#Curso').val(),
      "Fecha": fechaOriginal,
      "Colegio": this.capitalizarPalabras($('#Colegio').val()),
      "Docente": this.capitalizarPalabras($('#Docente').val()),
      "Instrucciones": $('#Instrucciones').val()
    };
    console.log(this.tieneOperaciones())
    if (!this.tieneOperaciones()) {
      this.alerta.fire({
        title: "Debe generar al menos una operación",
        icon: 'warning',
        confirmButtonText: "Ok"
      });
      return;
    }
  
    const img = new Image();
    img.src = `assets/images/aplicativos/generador-operaciones/fondo_pdf_${this.TAMANIO}_4.png`;
    let self = this;
    img.onload = function () {
        const pdf = new jsPDF('p', 'px', self.TAMANIO);
        let pageWidth = pdf.internal.pageSize.getWidth();
        let pageHeight = pdf.internal.pageSize.getHeight();
        var ubicacion_y = 50;
        pdf.addFileToVFS('letra', self.global.LETRAS[self.FuenteActual]);
        pdf.addFont("letra", "letra", "normal");
        pdf.setFont("letra","normal");
        
        pdf.setFont("letra","normal");
        pdf.setFont("letra","normal");
        pdf.setFont("letra","normal");
        pdf.setFontSize(12);
        let columns2 = [{ title: "", dataKey: 'text' },{ title: self.OPCIONES_ADICIONALES_PDF["Nombre"],dataKey: 'value' }];
        const data2 = [];
        for(let x in self.OPCIONES_ADICIONALES_PDF){
          if(x != 'Título'){
            data2.push({text: x , value: self.OPCIONES_ADICIONALES_PDF[x]})
          }
        }
        const options2 = {
          margin: { top: 43, left: 10, right: 10, bottom: 0 },
          styles: {
            fontSize: 12,
            cellPadding: 1,
            textColor: [0, 0, 0],
            overflow: 'linebreak',
            lineWidth: 0.1
          },

          alternateRowStyles: {
            fillColor: [245, 245, 245] 
          },
          didParseCell: function (data) {
            console.log(data,data.column.dataKey)
            if (data.column.dataKey === 'text') {
              data.cell.styles.fillColor = [133,205,218];
              data.cell.styles.textColor = [255, 255, 255];
            }
          },
          headerStyles: {
            fontStyle: 'normal',
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255]
          },
          columnStyles: {
            text: { 
              fillColor: [133,205,218],
              textColor: [255, 255, 255]
            }
          }
        };
        pdf.autoTable(columns2, data2, options2);



        let columns = [
          { title: self.OPCIONES_ADICIONALES_PDF["Título"], dataKey: 'frase', font: 'letra' }
        ];
        const data = [];
        const options = {
          margin: { top: 42, left: 10, right: 10, bottom: 0 },
          styles: {
            fontSize: 15,
            cellPadding: 1,
            textColor: [0, 0, 0],
            overflow: 'linebreak',
            lineWidth: 0.1
          },
          headerStyles: {
            halign: 'center',
            fillColor: [133,205,218], 
            textColor: [255, 255, 255]
          },
          alternateRowStyles: {
            fillColor: [245, 245, 245] 
          },
          theme: 'grid',
          columnStyles: {
            number: { 
              columnWidth: 40,
              halign: 'center'
            }
          }
        };
      
        pdf.autoTable(columns, data, options);
        pdf.setFont("letra","normal");
        ubicacion_y += 110;
        const rowHeight = 30; 
        let xPos = 40;
        let colCounter = 0;
        let colCounterResultado = 0;
        let maxRowHeight = 0;
        let maxColumn = 2;
        
        pdf.setFontSize(14);
        pdf.text("Operaciones:", 25, ubicacion_y);
        ubicacion_y += 50;
        let contador = 0;
        let contador_resultado = 0;
        Object.entries(self.operacionesGeneradas).forEach(([tipo, operaciones]) => {
          let mostrarHorizontal = true;
          if(tipo === 'suma'){
            mostrarHorizontal = self.OrientacionSuma === "true" ? true : false;
          }
          if(tipo === 'resta'){
            mostrarHorizontal = self.OrientacionResta === "true" ? true : false;
          }
          if(tipo === 'multiplicacion'){
            mostrarHorizontal = self.OrientacionMultiplicacion === "true" ? true : false;
          }
          if(tipo === 'division'){
            mostrarHorizontal = self.OrientacionDivision === "true" ? true : false;
          }
          console.log(tipo)
          if(self.fontSize > 20 && mostrarHorizontal){
            maxColumn = 1;
          }
          if(self.cifrasPorFila >=6 || self.numeroFilasResta >=6 || self.multiplicandoCifras >=6 || self.multiplicadorCifras >=6 || self.dividendoCifras >=6 || self.divisorCifras  >=6){
            maxColumn = 1;
          }
          let columnWidth = pageWidth / maxColumn - 10;
          
          operaciones.forEach((operacion, index) => {
            pdf.setFont("letra","normal");
            pdf.setFontSize(self.fontSize);
            let lineHeight = self.fontSize * 1.2;
            let operacionAltura = lineHeight; 
            let numero = (contador+1+")").toString();
            if (mostrarHorizontal) {
              let operacionTexto = `${operacion.valores.join('' + self.getOperator(tipo) + '')} =`;
          
              pdf.setTextColor(self.getColorCode(tipo)[0],self.getColorCode(tipo)[1],self.getColorCode(tipo)[2]);
              pdf.text(numero, xPos - 25, ubicacion_y);
          
              let numeroWidth = pdf.getTextWidth(numero);
              pdf.setTextColor(0, 0, 0);
          
              // Determinar el ancho máximo permitido
              let maxTextWidth = columnWidth - numeroWidth - 40;
              
              // Dividir el texto en varias líneas si es necesario
              let lineasTexto = pdf.splitTextToSize(operacionTexto, maxTextWidth);
          
              // Dibujar cada línea con espaciado
              lineasTexto.forEach((linea, i) => {
                  pdf.text(linea, xPos + numeroWidth + 5, ubicacion_y + (i * lineHeight));
              });
          
              // Ajustar la altura de la operación
              operacionAltura = lineasTexto.length * lineHeight;
          } else {
              pdf.setTextColor(self.getColorCode(tipo)[0],self.getColorCode(tipo)[1],self.getColorCode(tipo)[2]);
              pdf.text(numero, xPos-25, ubicacion_y);
              pdf.setTextColor(0, 0, 0);

              let yLinea = ubicacion_y + (operacion.valores.length * lineHeight) + 5;
              let maxWidth = Math.max(...operacion.valores.map(valor => pdf.getTextWidth(valor.toString())));
              operacion.valores.forEach((valor, i) => {
                pdf.text(valor.toString(), xPos + 30, ubicacion_y + (i * lineHeight));
              });
              pdf.text(self.getOperator(tipo), xPos+5, yLinea - lineHeight - 5);

              let lineStartX = xPos + 25;
              let lineEndX = lineStartX + maxWidth + 10;

              pdf.setLineWidth(2);
              pdf.setDrawColor(0, 0, 0);
              pdf.line(lineStartX, yLinea - 20, lineEndX, yLinea - 20);

              // pdf.line(xPos + 25, yLinea - 20, xPos + 55, yLinea - 20);
              operacionAltura = yLinea - ubicacion_y + 10;
            }

            maxRowHeight = Math.max(maxRowHeight, operacionAltura);

            colCounter++;
            if (colCounter === maxColumn) { 
              ubicacion_y += maxRowHeight + rowHeight;
              xPos = 40;
              colCounter = 0;
              maxRowHeight = 0;
            } else { 
              xPos += columnWidth;
            }

            if (ubicacion_y > pageHeight - 150) {
              console.log('aquiiiiik',index, contador);
              if(operaciones.length != (index+1)){
                pdf.addPage();
                ubicacion_y = 50;
                xPos = 40;
                colCounter = 0;
                maxRowHeight = 0;
              }
            }
            contador++;
          });
        });      
        if(self.MostrarResultado) {
          console.log('aquiiiiik')
          pdf.addPage();
          xPos = 40;
          ubicacion_y = 50;
          pdf.text("Resultados:", 25, ubicacion_y);
          ubicacion_y += 50;
          maxColumn = 3;
          Object.entries(self.operacionesGeneradas).forEach(([tipo, operaciones]) => {
            let columnWidth = pageWidth / maxColumn - 10;
            pdf.setFontSize(14);
            operaciones.forEach((operacion, index) => {
              pdf.setFont("letra", "normal");
              pdf.setFontSize(self.fontSize);
              let lineHeight = self.fontSize * 1.2;
              pdf.setTextColor(0, 0, 0);
              console.log(ubicacion_y)
              pdf.text((contador_resultado + 1 + ")").toString()+"  "+operacion.resultado.toString(), xPos + 30, ubicacion_y);
              colCounterResultado++;
        
              if (colCounterResultado === maxColumn) { 
                ubicacion_y += lineHeight + 3;
                xPos = 40;
                colCounterResultado = 0;
              } else { 
                xPos += columnWidth;
              }
        
              if (ubicacion_y > pageHeight - 150) {
                pdf.addPage();
                ubicacion_y = 50;
                xPos = 40;
                colCounterResultado = 0;
              }
        
              contador_resultado++;
            });
          });
        }
        
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          if(self.activoFondo){
            pdf.addImage(img, 'PNG', 0, 0, pageWidth, pageHeight);
          }
          pdf.setFont("Helvetica");
          pdf.setFontSize(10);
          pdf.setTextColor(255, 255, 255);
          // pdf.setDrawColor(0, 0, 0).setLineWidth(0.1).line(25, pageHeight - 30, pageWidth - 25, pageHeight - 30);
          pdf.text(`Matematicapps.cl  Un producto de Integritic.cl`, pageWidth / 2 - 80, pageHeight - 15);
          pdf.text(`Página ${i} de ${totalPages}`, pageWidth - 65, pageHeight - 15);
          
        }
        pdf.save(self.OPCIONES_ADICIONALES_PDF["Título"].replaceAll(' ', '_') + '.pdf');
        self.api.set_kpi_plataformas("Generador Operaciones Matemáticas","Descargó PDF");

        setTimeout(() => {
          $('#modalaso').addClass('hidden').hide();
        }, 800);
    };
  }
  setFechahoy(){
    console.log(this.fechaSeleccionada)
    $('#Fecha').val(this.fechaSeleccionada);
  }
  splitText(text: string, maxChars: number = 80): string[] {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    for (const word of words) {
      if ((currentLine + ' ' + word).trim().length <= maxChars) {
        currentLine = currentLine ? currentLine + ' ' + word : word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  }
  verlistado(estado){
    this.Listado = estado;
  }
  verResultado(estado){
    this.MostrarResultado = estado;
  }
  getTiposOperacion(): string[] {
    return Object.keys(this.operacionesGeneradas);
  }
  tieneOperaciones(){
    return Object.values(this.operacionesGeneradas).some(operaciones => operaciones.length > 0);
  }
  getOperator(tipo: string): string {
    const operadores: { [key: string]: string } = {
      suma: ' + ',
      resta: ' - ',
      multiplicacion: ' × ',
      division: ' ÷ '
    };
    return operadores[tipo] || ' ? ';
  }
  getColor(tipo: string): string {
    const operadores: { [key: string]: string } = {
      suma: '#98deff',
      resta: '#ede08c',
      multiplicacion: '#ffcd85',
      division: '#aff3ba'
    };
    return operadores[tipo] || '#98deff';
  }
  getColorCode(tipo: string): number[] {
    const operadores: { [key: string]: number[] } = {
      suma: [117,117,117],//[152, 222, 255],
      resta: [117,117,117],//[237, 224, 140],
      multiplicacion: [117,117,117],//[255, 205, 133],
      division: [117,117,117],//[175, 243, 186]
    };
    return operadores[tipo] || [152, 222, 255];
  }
  getOrientacion(tipo: string): boolean {
    const orientaciones: { [key: string]: boolean } = {
      suma: this.OrientacionSuma === "true" ? true : false,
      resta: this.OrientacionResta === "true" ? true : false,
      multiplicacion: this.OrientacionMultiplicacion === "true" ? true : false,
      division: this.OrientacionDivision === "true" ? true : false
    };
  
    return orientaciones[tipo]; // Si es true, vertical; si es false, horizontal
  }
  getOperatorIcon(tipo: string): string {
    const operadores: { [key: string]: string } = {
      suma: ' icon-plus-circle2 ',
      resta: ' icon-minus-circle2 ',
      multiplicacion: ' icon-cancel-circle2 ',
      division: ' icon-percent '
    };
    return operadores[tipo] || ' ? ';
  }
  async guardarOperaciones(numero, valor,tipo,index_operacion) {
    console.log(this.operacionesGeneradas[tipo],numero,index_operacion)
    this.operacionesGeneradas[tipo][index_operacion].valores[valor] = await this.quitarLetras(this.operacionesGeneradas[tipo][index_operacion].valores[valor]);
    setTimeout(() => {
        const inputElement:any = document.querySelector(`[data-operador="${numero}-${valor}"]`) as HTMLInputElement;
        if (inputElement) {
            if (inputElement instanceof HTMLInputElement || inputElement instanceof HTMLTextAreaElement) {
                $((`[data-operador="${numero}-${valor}"]`)).val(this.operacionesGeneradas[tipo][index_operacion].valores[valor]).trigger("change");
                inputElement.focus();
                this.ModificarResultados(numero,valor,tipo,index_operacion);
                // console.log("Elemento encontrado y foco aplicado:", inputElement);
            } else {
                // console.log("El elemento no es un campo de texto interactivo.");
            }
        } else {
            // console.log("Elemento no encontrado");
        }
    }, 0);
  }
  quitarLetras(valor: string): number {
    return Number(valor.replace(/[a-zA-Z]/g, '')); // Elimina todas las letras
  }
  capitalizarPalabras(texto) {
    let palabras = texto.toLowerCase().split(' ');
    for (let i = 0; i < palabras.length; i++) {
      palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
    }
    let resultado = palabras.join(' ');
    return resultado;
  }
  ModificarResultados(numero,valor,tipo,index_operacion){
    let ListadoOperacion = this.operacionesGeneradas[tipo][index_operacion];
    let resultado = 0;
    if(ListadoOperacion){
      if(tipo == "suma"){
        resultado = ListadoOperacion.valores.reduce((acc, num) => Number(acc) + Number(num), 0);
      }else if(tipo == "resta"){
        resultado = Number(ListadoOperacion.valores[0]) - Number(ListadoOperacion.valores[1]);
      }else if(tipo == "multiplicacion"){
        resultado =  Number(ListadoOperacion.valores[0]) *  Number(ListadoOperacion.valores[1]);
      }else{
        resultado =  Number(ListadoOperacion.valores[0]) / Number(ListadoOperacion.valores[1]);
      }
      ListadoOperacion.resultado = resultado;
    }
  }
  mensajeReinicio = (operacion) => {
    this.alerta.fire({
      title: "¿Está seguro que desea hacer cambios?",
      text: "Se eliminarán las operaciones generadas actualmente",
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.operacionesGeneradas = {
          suma: [],
          resta: [],
          multiplicacion: [],
          division: []
        };
      }else{
        this.identificarOtroActivado(operacion);
      }
    });
  }
  getOperacionIndex(tipoIndex: number, i: number): number {
    let total = 0;
    for (let j = 0; j < tipoIndex; j++) {
      total += this.operacionesGeneradas[this.getTiposOperacion()[j]].length;
    }
    return total + i + 1;
  }
  onInputChange(){
    this.operacionesGeneradas = {
      suma: [],
      resta: [],
      multiplicacion: [],
      division: []
    };
    this.getEjemploSuma();
    this.getEjemploResta();
    this.getEjemploMultiplicacion();
    this.getEjemploDivision();
  }
  async getEjemploSuma(){
    this.EjemploSuma = await this.generarSumaEjemplo();
    console.log(this.EjemploSuma)
  }
  async getEjemploResta(){
    this.EjemploResta = await this.generarRestaEjemplo();
    console.log(this.EjemploResta)
  }
  async getEjemploMultiplicacion(){
    this.EjemploMultiplicacion = await this.generarMultiplicacionEjemplo();
    console.log(this.EjemploMultiplicacion)
  }
  async getEjemploDivision(){
    this.EjemploDivision = await this.generarDivisionEjemplo();
    console.log(this.EjemploDivision)
  }
  async generarSumaEjemplo() {
    let sumandos = [];
    let referencia = this.generarNumeroAleatorioSuma(this.cifrasPorFila, true);

    for (let j = 0; j < this.numeroFilas; j++) {
        let permitirLlevadas = Math.random() * 100 < this.conLlevadas;
        let numeroGenerado;
        let intentos = 0;

        do {
            numeroGenerado = this.generarNumeroAleatorioSuma(this.cifrasPorFila, permitirLlevadas);
            intentos++;
            if (intentos > 10) break;
        } while (sumandos.includes(numeroGenerado) || numeroGenerado > referencia);

        sumandos.push(numeroGenerado);
        referencia = numeroGenerado;
    }

    let resultado = sumandos.reduce((acc, num) => acc + num, 0);
    return {
        valores: sumandos,
        resultado: resultado
    };
  }
  
  async generarRestaEjemplo() {
    let permitirLlevadas = Math.random() * 100 < this.conLlevadas;
    let minuendo = this.generarNumeroAleatorioResta(this.cifrasPorFilaResta, permitirLlevadas, Number.MAX_SAFE_INTEGER);
    let valores = [minuendo];
    let valorRestante = minuendo; // Mantiene el resultado parcial para evitar negativos

    for (let j = 1; j < this.numeroFilasResta; j++) {
        if (valorRestante <= 0) break; // Detiene la iteración si el valor restante llega a 0

        let maxSustraendo = Math.max(1, Math.floor(valorRestante / 2)); // Evita reducciones drásticas
        let sustraendo;
        let intentos = 0;

        do {
            sustraendo = this.generarNumeroAleatorioResta(this.cifrasPorFilaResta, permitirLlevadas, maxSustraendo);
            intentos++;
            if (intentos > 10) break;
        } while (sustraendo > maxSustraendo); // Asegura que el sustraendo sea seguro

        valores.push(sustraendo);
        valorRestante -= sustraendo; // Actualiza el valor restante
    }

    let resultado = valores.reduce((acc, num) => acc - num);

    return {
        valores: valores,
        resultado: resultado
    };
}
  
  async generarMultiplicacionEjemplo() {
    let multiplicando = this.generarNumeroAleatorioMultiplicacion(this.multiplicandoCifras);
    let multiplicador = this.generarNumeroAleatorioMultiplicacion(this.multiplicadorCifras);
    let resultado = multiplicando * multiplicador;

    return {
      valores: [multiplicando, multiplicador],
      resultado: resultado
    };
  }
  
  async generarDivisionEjemplo() {
    let dividendo, divisor;
    let intentos = 0;

    do {
        dividendo = this.generarNumeroAleatorioDivision(this.dividendoCifras);
        divisor = this.generarNumeroAleatorioDivision(this.divisorCifras);
        intentos++;
        if (intentos > 10) break;
    } while (divisor === 0 || divisor === 1 || dividendo <= divisor || dividendo % divisor !== 0);

    let resultado = dividendo / divisor;

    return {
        valores: [dividendo, divisor],
        resultado: resultado
    };
  }
  QuitarDestacado(id){
    $('#'+id).removeClass('error pulso-css');
  }
  getOperacionesActivas(): SafeHtml {
    const operaciones = [
      this.activoSuma ? '<b style="color: #98deff">Suma</b>' : '',
      this.activoResta ? '<b style="color: #ede08c">Resta</b>' : '',
      this.activoMultiplicacion ? '<b style="color: #ffcd85">Multiplicación</b>' : '',
      this.activoDivision ? '<b style="color: #aff3ba">División</b>' : ''
    ].filter(op => op !== '').join(' - ');

    return this.sanitizer.bypassSecurityTrustHtml(operaciones);
  }
  descargarlibroderegalo(){
    window.open("https://drive.google.com/file/d/1mLn24wYw9Om9ScMvfX_mknA6TBBrYAUZ/view?usp=sharing", '_blank');
    this.api.set_kpi_plataformas("Generador Operaciones Matemáticas","Descargó libro de regalo");

  }
}